<template>
  <div>
    <div class="home_tabs">
      <div class="container">
        <div class="row">
          <div class="_tabs">
            <ul>
              <li >
                <router-link to="/" :class="groupName == 'mbsb' ? 'item visible' : 'item'">
                  МБСБ төгсөгч
                </router-link>
              </li>
              <li  >
                <router-link to="UG_tugsugch" :class="groupName == 'ih' ? 'item visible' : 'item'">
                  Их дээд сургууль төгсөгч
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div v-show="groupName == 'mbsb'">
      <MBSB_tugsugch></MBSB_tugsugch>
    </div>
    <div v-show="groupName == 'ih'">
      <IDSTugsugch></IDSTugsugch>
    </div>


<!--    <div class="container">-->
<!--      <div class="row">-->
<!--        <div class="col-12">-->
<!--          <div class=" Statistic Statistic_tabs_cont " id="Statistic" style="margin-top: 20px;">-->
<!--            <ul style="padding: 0; margin: 0">-->
<!--              <li>-->
<!--                <router-link class="tab_router"  to="/">-->
<!--                  &lt;!&ndash;              <img  class="tugsugch_color"  alt=" " :src="'/survey/tabs_img/mb_'+((tab1 === true)?'1':'2')+'.svg'" >&ndash;&gt;-->
<!--                  <img  class="tugsugch_color"  alt=" " src="../../assets/img/tabs_img/mb_1.svg">-->
<!--                  &lt;!&ndash;              <img  class="tugsugch_color"  alt=" " :src="`/survey/tabs_img/mb_${tab1 === true ? '2' : '1'}.svg`" >&ndash;&gt;-->
<!--                </router-link>-->
<!--              </li>-->
<!--              <li>-->
<!--                <router-link class="tab_router"  to="/hudulmur_erhlelt">-->
<!--                  &lt;!&ndash;              <img class="hudulmur_erhlelt_color" alt=" "  :src="'/survey/tabs_img/he_'+((tab2 === true)?'1':'2')+'.svg'">&ndash;&gt;-->
<!--                  <img class="hudulmur_erhlelt_color" alt=" " src="../../assets/img/tabs_img/he_1.svg">-->
<!--                  &lt;!&ndash;            <img v-else class="brdr_btm hudulmur_erhlelt_color" alt=" " src="../../assets/img/tabs_img/he_2.svg">&ndash;&gt;-->
<!--                </router-link>-->
<!--              </li>-->
<!--              <li>-->
<!--                <router-link class="tab_router"  to="/ndsh_tulult">-->
<!--                  &lt;!&ndash;              <img class="ndsh_tulult_color" alt=" " :src="'/survey/tabs_img/nd_'+((tab3 === true)?'1':'2')+'.svg'">&ndash;&gt;-->
<!--                  <img class="ndsh_tulult_color" alt=" " src="../../assets/img/tabs_img/nd_1.svg">-->
<!--                  &lt;!&ndash;            <img v-else class="ndsh_tulult_color" alt=" " src="../../assets/img/tabs_img/nd_2.svg">&ndash;&gt;-->
<!--                </router-link>-->
<!--              </li>-->
<!--              <li>-->
<!--                <router-link class="tab_router"  to="/halamj">-->
<!--                  <img  class="halamj_color" alt=" " src="../../assets/img/tabs_img/hu_1.svg">-->
<!--                  &lt;!&ndash;            <img v-else class="halamj_color" alt=" " src="../../assets/img/tabs_img/hu_2.svg">&ndash;&gt;-->
<!--                </router-link>-->
<!--              </li>-->

<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

  </div>
</template>

<script>

import MBSB_tugsugch from "./MBSB_tugsugch";
import IDSTugsugch from "./IDSTugsugch";
export default {
  name: "statistic",
  props: ['selectedtabnumber', 'groupName'],
  components:{
    MBSB_tugsugch,
    IDSTugsugch
  },
  data() {
    return {
      active: 'tugsugch_color1',
      showAdvancedSearch: false,
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      selectedtab: 0
    }
  },
  methods: {
    tabSwitch1(){
      console.log("tabSwitch1 >>> ");
    },
//     tabSwitch(tab_val){
//       console.log("tab_val >>> ");
//       console.log(tab_val);
//
//       // console.log("    >>> ");
//       // console.log("    >>> ");
//       // console.log("    >>> ");
//       //
//       // console.log("tab 1  >>> " + this.tab1);
//       // console.log("tab 2  >>> " + this.tab2);
//       // console.log("tab 3  >>> " + this.tab3);
//       // console.log("tab 4  >>> " + this.tab4);
//       // this.tab1 = true;
//   // switch (tab_val){
//   //   case 1:  this.tab2 = false; this.tab3 = false; this.tab4 = false; this.tab1 = true; break;
//   //   case 2:  this.tab1 = false; this.tab3 = false; this.tab4 = false; this.tab2 = true; break;
//   //   case 3:  this.tab1 = false; this.tab2 = false; this.tab4 = false; this.tab3 = true; break;
//   //   case 4:  this.tab2 = false; this.tab3 = false; this.tab1 = false; this.tab4 = true; break;
//   //   default: break;
//   // }
// }
  }
}
</script>

<style scoped>

</style>
