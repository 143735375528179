<template>
    <div class="container" >
        <div class="row">
            <div class="col-12">
                <div class="Statistic Statistic_tabs_cont  " id="Statistic" style="margin-top: 20px;" >
                  <div class="devide">
                    <router-link class="tab_router"  to="/UG_tugsugch">
                      <div class="_icon"><div class="icon_01"></div></div>
                      Төгсөгчдийн ерөнхий мэдээлэл
                    </router-link>
                    <router-link class="tab_router"  to="/UG_hudulmur_erhlelt">
                      <div class="_icon"><div class="icon_02"></div></div>
                      Хөдөлмөрийн зах зээлийн мэдээлэл
                    </router-link>
                  </div>
                  <div class="devide">
                    <router-link class="tab_router"  to="/UG_ndsh_tulult">
                      <div class="_icon"><div class="icon_03"></div></div>
                      Нийгмийн даатгалын <br> төлөлтийн мэдээлэл
                    </router-link>
<!--                    <router-link class="tab_router"  to="/UG_halamj">-->
<!--                      <div class="_icon"><div class="icon_04"></div></div>-->
<!--                      Халамжийн үйлчилгээнд хамрагдалт-->
<!--                    </router-link>-->
                  </div>

                </div>
            </div>
        </div>
<!--      <div class="row">-->
<!--        <div class="col-lg-12">-->
<!--          <div style="padding-top: 10px">-->
<!--            <p style="font-size: 12px">Эх сурвалж: Боловсролын салбарын мэдээллийн системийн лавлагааны сан дахь дээд боловсролын байгууллага төгсөгчийн тоон мэдээлэл 2021.12.31-ний байдлаар</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
</template>

<script>
export default {
    name: "IDSTugsugch"
}
</script>

<style scoped>

</style>
