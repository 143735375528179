import { render, staticRenderFns } from "./MBSB_tugsugch.vue?vue&type=template&id=cf84c490&scoped=true&"
import script from "./MBSB_tugsugch.vue?vue&type=script&lang=js&"
export * from "./MBSB_tugsugch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf84c490",
  null
  
)

export default component.exports